/*
    C-POS EXTENSION
    Extension du composant générique c-pos
    https://github.com/ita-design-system/c-pos.scss
    Ce fichier doit servir à étendre ou surcharger les fonctionnalités
    du composant c-pos selon les besoins du projet
*/
$briks-components-generic: (
    // Nom du composant, obligatoirement pos
    pos: ( 
        // Extension activée true ou false
        enabled: true, 
        // Responsive activée true ou false pour l'extension
        responsive: true, 
        // Valeurs par défaut de l'extension
        defaults: (),
        // Liste des modifieurs contenant chacun une liste de propriétés qui 
        // soit surchargent les propriétés par défaut
        // soit ajoutent des propriétés
        // soit les deux
        modifiers: ( 
            // Ajout de valeurs de propriétés liées aux design tokens d'espacements
            // c-pos m-left-50-minus-600px
            left-50-minus-600px: (
                left: calc(50% - 600px)
            ),
            // c-pos m-left-50-minus-655px
            left-50-minus-655px: (
                left: calc(50% - 655px)
            ),
            // c-pos m-effect-hover-1
            effect-hover-1:(
                pseudo-classes: (
                    hover: (
                        transform: translateY(-18px)
                    )
                )
            ),
        )
    )
);